<template>
  <div class="row">
    <div class="col-xl-8">
      <div v-if="loading" style="height: 386.055px" class="parent-div">
        <div
          class="spinner-border center-div"
          style="height: 4rem; width: 4rem"
          role="status"
        >
          <span class="visually-hidden">{{ $t("events.loading") }}...</span>
        </div>
      </div>
      <div v-if="!loading">
        <div
          class="image-container"
          :class="{ 'no-image': event.image === null }"
        >
          <EventImage
            v-if="event.image !== null"
            :filename="event.image"
            :site_id="event.site.id"
            :thumb="false"
            :id="'event_image' + event.image"
            :storage="event.storage_account || 1"
          />
          <div v-else class="placeholder"></div>
        </div>
      </div>

      <div
        v-if="!loading"
        class="m-t-xxs m-b-md image-footer"
        :class="event.status === 'blacklisted' ? 'bg-danger' : 'bg-primary'"
      >
        <div v-if="event.status !== 'blacklisted'">
          <p id="event-no-claims">
            {{
              blacklist.local.claims +
              " " +
              $t("event.claims_outstanding").toUpperCase()
            }}
          </p>
        </div>
        <div v-else>
          <p v-if="blacklist.local?.reason">
            {{ $t("vehicle.blacklisted", { text: "LOCALLY" }).toUpperCase() }}
            <strong>FOR</strong> {{ blacklist.local.reason }}
          </p>
          <p v-for="entry in blacklist.national.by" id="event-bl-reason">
            {{ $t("vehicle.blacklisted", { text: "by" }).toUpperCase() }}
            <span class="fn-weight-500"> {{ entry.name }} </span>
            <span>
              <span>
                FOR <strong> {{ entry.reason }} </strong>
              </span>
            </span>
          </p>
          <p
            v-if="!blacklist.local?.reason && blacklist.national.by.length < 1"
          >
            VEHICLE NO LONGER BLACKLISTED. THIS VEHICLE WAS BLACKLISTED AT THE
            TIME OF THIS EVENT.
          </p>
        </div>
      </div>

      <div
        v-if="$can('events-unblacklist') || $can('events-blacklist')"
        class="card"
        id="watchlist-min"
      >
        <div class="card-body">
          <span
            class="vrm bold number-plate-gbr"
            style="font-size: 16px; cursor: pointer"
            @click.stop.prevent="copyToClipboard(vehicle.plate)"
            >{{ vehicle.plate }}</span
          >
          <!-- Add to blacklist btn -->
          <button
            class="btn btn-danger float-end bg-danger"
            v-if="event.status === 'active'"
            @click="() => addToBlacklistModal.show()"
            style="width: fit-content"
          >
            <div
              v-if="updatingBlacklist"
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
            <i
              :class="updatingBlacklist ? 'display-none' : ''"
              class="material-icons-outlined"
            >
              add
            </i>
            {{ $t("blacklist.add_to_blacklist") }}
          </button>

          <!-- Remove from blacklist btn -->
          <button
            class="btn btn-danger float-end bg-danger"
            v-if="event.status === 'blacklisted'"
            @click="() => unblacklistModal.show()"
            style="width: fit-content"
          >
            <div
              v-if="updatingBlacklist"
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
            <i
              :class="updatingBlacklist ? 'display-none' : ''"
              class="material-icons-outlined"
            >
              close
            </i>
            {{ $t("blacklist.remove_from_blacklist") }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="$can('events-unblacklist') || $can('events-blacklist')"
      class="col-xl-4"
    >
      <div class="card" id="watchlist">
        <div class="card-body">
          <span
            class="vrm bold number-plate-gbr"
            style="font-size: 1.3rem; cursor: pointer"
            @click.stop.prevent="copyToClipboard(vehicle.plate)"
          >
            {{ vehicle.plate }}
          </span>
          <!-- Add to blacklist btn -->
          <button
            class="btn btn-danger float-end bg-danger"
            v-if="event.status === 'active'"
            @click="() => addToBlacklistModal.show()"
            style="width: fit-content"
          >
            <div
              v-if="updatingBlacklist"
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
            <i
              :class="updatingBlacklist ? 'display-none' : ''"
              class="material-icons-outlined"
            >
              add
            </i>
            {{ $t("blacklist.add_to_blacklist") }}
          </button>

          <!-- Remove from blacklist btn -->
          <button
            class="btn btn-danger float-end bg-danger"
            v-if="event.status === 'blacklisted'"
            @click="() => unblacklistModal.show()"
            style="width: fit-content"
          >
            <div
              v-if="updatingBlacklist"
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
            <i
              :class="updatingBlacklist ? 'display-none' : ''"
              class="material-icons-outlined"
            >
              close
            </i>
            {{ $t("blacklist.remove_from_blacklist") }}
          </button>
        </div>
      </div>

      <div
        class="card alert alert-custom alert-indicator-top indicator-warning"
      >
        <div class="card-title fn-weight-500">
          {{ $t("event.vehicle_information") }}
        </div>
        <div v-if="loading" class="parent-div">
          <div
            class="spinner-border center-div"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
        <div v-else class="card-body grid">
          <div class="text-muted">{{ $t("global.make") }}</div>
          <div class="">{{ vehicle.context.make }}</div>
          <div class="text-muted">{{ $t("global.model") }}</div>
          <div class="">{{ vehicle.context.model }}</div>
          <div class="text-muted">{{ $t("global.colour") }}</div>
          <div>{{ vehicle.context.colour }}</div>
        </div>
      </div>

      <div class="card alert alert-custom alert-indicator-top indicator-info">
        <div class="card-title fn-weight-500">
          {{ $t("event.claim_information") }}
        </div>
        <div v-if="loading" class="parent-div">
          <div
            class="spinner-border center-div"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
        <div v-else class="card-body grid">
          <div class="text-muted">{{ $t("event.claims_outstanding") }}</div>
          <div>{{ blacklist.local.claims }}</div>
          <div class="text-muted">{{ $t("event.reason_for_watchlist") }}</div>
          <div v-if="blacklist.local.reason == null">{{ $t("event.n_a") }}</div>
          <div v-else>{{ blacklist.local.reason }}</div>
          <div class="text-muted">{{ $t("event.money_owed") }}</div>
          <div>£{{ blacklist.local.value }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Notes -->
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title fn-weight-500">{{ $t("event.notes") }}</h5>
            <div v-if="loading" class="parent-div">
              <div
                class="spinner-border center-div"
                role="status"
                style="margin-right: 5px"
              >
                <span class="visually-hidden"
                  >{{ $t("global.loading") }}...</span
                >
              </div>
            </div>
            <div v-if="!loading" id="card-content" style="min-height: 326px">
              <p v-if="vehicle.notes.length < 1" class="no-notes">
                {{ $t("event.no_notes") }}
              </p>
              <div
                v-else
                v-for="note in vehicle.notes"
                :key="note.index"
                class="msg-wrapper m-b-xs"
              >
                <span> {{ note.note }} </span>
                <div class="note-timestamp m-t-xxs">
                  <Timestamp :time="note.created_at" />
                  <span> by {{ note.user.email }} </span>
                  <span
                    v-if="$can('vehicles-delete-note')"
                    class="text-danger note-delete"
                    @click="deleteNote(note)"
                    style="margin-left: 5px;"
                    >delete</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer" id="events">
            <input
              type="text"
              v-model="newNote"
              placeholder="Write your message here ..."
              id="newNote"
              @keydown.enter="addNewNote"
            />
            <i
              v-if="!addingNewNote"
              class="material-icons send-icon"
              title="Press Enter to send"
              @click="addNewNote"
            >
              send
            </i>
            <div
              v-else
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Customer Service Message -->
      <div
        v-if="$can('events-unblacklist') || $can('events-blacklist')"
        class="col-xl-6"
      >
        <div class="card">
          <div class="card-body">
            <h5 class="card-title fn-weight-500">
              {{ $t("event.customer_service_message") }}
            </h5>
            <MessageCard
              alertType="indicator-info"
              :alertTitle="$t('claims.new.comments')"
              :alertText="$t('event.csm_info')"
              id="commentcard"
            />
            <MessageCard
              alertType="indicator-danger"
              :alertTitle="$t('claims.new.very_important')"
              :alertText="$t('event.csm_danger')"
              id="dangercard"
            />
            <p
              v-if="vehicle.customerServiceMessage == undefined"
              class="no-notes"
            >
              {{ $t("event.no_csm") }}
            </p>
            <div v-else class="msg-wrapper m-b-xs">
              <span> {{ vehicle.customerServiceMessage.message }} </span>
              <div class="note-timestamp m-t-xxs">{{ csm_timestamp }}</div>
            </div>
            <button
              class="btn btn-sm btn-danger float-end"
              @click="deleteCustomerServiceMessage"
              v-if="vehicle.customerServiceMessage != undefined"
            >
              Delete
            </button>
          </div>
          <div class="card-footer" id="events">
            <input
              type="text"
              v-model="newCSM"
              placeholder="Write your message here ..."
              id="newNote"
              @keydown.enter="updateCustomerServiceMessage"
            />
            <i
              v-if="!updatingCSM"
              class="material-icons send-icon"
              title="Press Enter to send"
              @click="updateCustomerServiceMessage"
            >
              send
            </i>
            <div
              v-else
              class="spinner-border"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add to Blacklist modal -->
    <div
      class="modal fade"
      id="addToBlacklistModal"
      tabindex="-1"
      aria-labelledby="addToBlacklistTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-h-xxl p-v-xxl">
          <div class="modal-title">
            <h4 class="m-r-sm textAlign-center">
              {{ $t("blacklist.add_to_blacklist") }}
            </h4>
            <p class="modal-sub-title">
              {{ $t("blacklist.blacklist_modal_message") }} {{ vehicle.plate }}
            </p>
            <select class="form-select m-b-md" v-model="reason">
              <option value="" selected>Select A Reason</option>
              <option
                v-for="reason in blacklistReasons"
                :value="reason"
                :key="reason"
              >
                {{ reason }}
              </option>
            </select>
            <div class="flex-center">
              <button
                class="btn btn-secondary m-r-sm"
                @click="() => addToBlacklistModal.hide()"
              >
                {{ $t("global.close") }}
              </button>
              <button class="btn btn-primary" @click="addToBlacklist">
                {{ $t("blacklist.add_to_blacklist") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Remove from Blacklist Modal -->
    <div
      class="modal fade"
      id="unblacklistModal"
      tabindex="-1"
      aria-labelledby="unblacklistModal"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-h-xxl p-v-xxl">
          <div class="modal-title">
            <h4 class="m-r-sm textAlign-center">
              {{ $t("blacklist.remove_from_blacklist") }}
            </h4>
          </div>
          <div class="flex p-v-sm" style="flex-wrap: nowrap">
            <i class="material-icons warning-icon m-r-sm"> warning_amber </i>
            <!-- <span> {{$t('blacklist.are_you_sure')}} ? {{$t('blacklist.modal_message')}} </span> -->
            <div>
              <h4 class="text-center">{{ $t("blacklist.are_you_sure") }} ?</h4>
              <span> {{ $t("blacklist.modal_message") }} </span>
            </div>
          </div>
          <div class="flex-center">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => unblacklistModal.hide()"
            >
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="unblacklist">
              {{ $t("blacklist.remove_from_blacklist") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventImage from "../../components/EventImage.vue";
import EventService from "../../services/event.service";
import vehicleService from "../../services/vehicle.service";
import VehicleNotes from "../vehicle/VehicleNotes.vue";
import Timestamp from "../../components/Timestamp.vue";
import Swal from "sweetalert2";
import { DateTime } from "luxon";
import MessageCard from "../../components/MessageCard.vue";
import eventService from "../../services/event.service";
import axios from "axios";
import authHeader from "../../services/auth-header";

export default {
  name: "Event",
  components: {
    EventImage,
    VehicleNotes,
    Timestamp,
    MessageCard,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      event: {
        id: -1,
      },
      selectedClient: this.$store.state.auth.user.selectedClient,
      vehicle: {
        notes: [],
        customerServiceMessage: {
          message: "",
        },
        context: {
          make: "",
          model: "",
          colour: "",
        },
      },
      blacklist: {
        national: {},
        local: {
          blacklisted_at: null,
          reason: null,
        },
      },
      updatingBlacklist: false,
      loading: true,
      newNote: "",
      addingNewNote: false,
      newCSM: "",
      updatingCSM: false,
      addToBlacklistModal: null,
      unblacklistModal: null,
      reason: "",
      blacklistReasons: [
        "Drive Off",
        "No Means of Payment",
        "Fly Tipping",
        "Theft",
        "Aggressive",
        "Plate Mismatch",
      ],
      updatingBlacklist: false,
    };
  },
  mounted() {
    this.getEventDetails();
    this.addToBlacklistModal = new bootstrap.Modal(
      document.getElementById("addToBlacklistModal")
    );
    this.unblacklistModal = new bootstrap.Modal(
      document.getElementById("unblacklistModal")
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$success(`Copied ${text} to clipboard`);
        })
        .catch((error) => {
          console.error("Clipboard copy failed", error);
          this.$error("Failed to copy to clipboard");
        });
    },
    getEventDetails() {
      this.event.id = parseInt(this.$route.params.id || -1);
      this.loading = true;
      EventService.getEvent(this.selectedClient, this.event.id)
        .then((response) => {
          this.event = response.data;
          this.vehicle = response.data.vehicle;
          this.vehicle.customerServiceMessage =
            response.data.vehicle.customerServiceMessage;
          this.blacklist.local = response.data.blacklist.local;
          this.blacklist.national = response.data.blacklist.national;
          this.loading = false;
        })
        .catch((error) => {
          this.$error(this.$t("event.event_error"), error);
          this.loading = false;
        });
    },
    addNewNote() {
      this.addingNewNote = true;
      if (this.newNote.length <= 1) {
        this.$error(this.$t("event.valid_note"));
        this.newNote = "";
        this.addingNewNote = false;
        return;
      }
      vehicleService
        .putVehicleNote(this.selectedClient, this.vehicle.id, this.newNote)
        .then((response) => {
          this.vehicle.notes = response.data.note;
          this.newNote = "";
          this.addingNewNote = false;
        })
        .catch((error) => {
          this.error(this.$t("vehicle_notes.error_msg"), error);
          this.addingNewNote = false;
          this.newNote = "";
        });
    },
    deleteNote(note) {
      if (!this.$can("vehicles-delete-note")) {
        this.$error("You don't have permission to delete notes", null);
        return;
      }
      axios
        .delete(
          `https://api.varsanpr.com/api/vehicle/${this.vehicle.id}/notes/${note.id}?client_id=${this.selectedClient}`,
          {
            headers: authHeader(),
          }
        )
        .then(() => {
          this.vehicle.notes = this.vehicle.notes.filter(
            (n) => n.id != note.id
          );
          this.$success("Vehicle note deleted!");
        })
        .catch((error) => {
          this.$error("Error deleting vehicle note", error);
        });
    },
    updateCustomerServiceMessage() {
      this.updatingCSM = true;
      if (this.newCSM.length <= 1) {
        this.$error(this.$t("event.valid_note"));
        this.newCSM = "";
        this.updatingCSM = false;
        return;
      }
      vehicleService
        .putCustomerServiceMessage(
          this.selectedClient,
          this.vehicle.id,
          this.newCSM
        )
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            this.vehicle.customerServiceMessage = {
              message: this.newCSM,
              created_at: DateTime.now().toISO(),
            };
            this.newCSM = "";
            this.updatingCSM = false;
            this.$success(
              this.$t("csm.success_msg") + " " + this.vehicle.plate
            );
          }
        })
        .catch((error) => {
          this.$error(this.$t("csm.error_msg"), error);
          this.updatingCSM = false;
          this.newCSM = "";
        });
    },
    deleteCustomerServiceMessage() {
      this.updatingCSM = true;
      vehicleService
        .deleteCustomerServiceMessage(this.selectedClient, this.vehicle.id)
        .then((response) => {
          if (response.status == 200) {
            this.vehicle.customerServiceMessage = undefined;
            this.updatingCSM = false;
            this.$success(
              this.$t("csm.success_msg") + " " + this.vehicle.plate
            );
          }
        })
        .catch((error) => {
          this.$error(this.$t("csm.error_msg"), error);
          this.updatingCSM = false;
        });
    },
    addToBlacklist() {
      if (this.reason == null || this.reason == "") {
        this.$warning(this.$t("blacklist.reason"));
      }
      if (this.reason.length < 1) {
        this.$warning(this.$t("blacklist.invalid_reason"));
      }

      this.updatingBlacklist = true;
      eventService
        .blacklist(this.event.site.id, this.event.id, this.reason)
        .then((response) => {
          console.log(response);
          this.$success(this.$t("blacklist.success_message"));
          this.blacklist.local.reason = this.reason;
          this.event.status = "blacklisted";
        })
        .catch((error) => {
          this.$error(this.$t("blacklist.error_message"), error);
        })
        .finally(() => {
          this.updatingBlacklist = false;
          this.addToBlacklistModal.hide();
        });
    },
    unblacklist() {
      vehicleService
        .unblacklistVehicle(this.selectedClient, this.vehicle.plate)
        .then((response) => {
          this.$success(this.$t("blacklist.unblacklist_success"));
          this.event.status = "active";
        })
        .catch((error) => {
          this.$error(this.$t("blacklist.unblacklist_error"), error);
        })
        .finally(() => {
          this.unblacklistModal.hide();
        });
    },
    addToBlacklist() {
      vehicleService
        .blacklistVehicle(this.selectedClient, this.vehicle.plate, this.reason)
        .then((response) => {
          this.$success(this.$t("blacklist.success_message"));
          this.event.status = "active";
        })
        .catch((error) => {
          this.$error(this.$t("blacklist.error_message"), error);
        })
        .finally(() => {
          this.addToBlacklistModal.hide();
        });
    },
  },
  computed: {
    csm_timestamp: function () {
      if (this.vehicle.customerServiceMessage.created_at == undefined)
        return "";
      return DateTime.fromFormat(
        `${this.vehicle.customerServiceMessage.created_at}`,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ).toFormat("dd/MM/yyyy HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.image-container {
  margin: 10px 0;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  position: relative;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.placeholder::before {
  content: "No Image";
}

.image-container:hover {
  cursor: pointer;
}

.image-container::before {
  content: "\e8ff";
  font-family: "Material Icons";
  font-size: 36px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(22, 22, 22, 0.384);
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.image-container:hover::before {
  opacity: 1;
}

.image-footer {
  padding: 15px;
}

.image-footer p {
  font-size: 20px;
  font-weight: 100;
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid div {
  margin-bottom: 10px;
}

.no-notes {
  font-weight: 100;
  font-style: italic;
  color: var(--font-color-secondary);
}

#card-content {
  overflow-y: scroll;
  max-height: 300px;
}

.msg-wrapper {
  width: fit-content;
  padding: 10px 25px;
  border-radius: 10px;
  overflow: hidden;
}

.note-timestamp {
  font-size: 11px;
}

#newNote {
  border: none;
  width: 92%;
}

#newNote:focus {
  outline: none;
}

.send-icon {
  vertical-align: middle;
  color: var(--font-color-secondary);
  position: relative;
}

.send-icon:hover {
  color: white;
  cursor: pointer;
}

.warning-icon {
  font-size: 50px;
  color: var(--utility-orange);
}

#watchlist {
  display: none;
}

@media (min-width: 1920px) {
  #watchlist {
    display: block;
  }

  #watchlist-min {
    display: none;
  }
}

.note-delete:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
