<template>
  <div class="col">
    <label for="vehicleRegistrationFilter" class="form-label">
      {{ $t("events.filters.vehicle_registration") }}
    </label>
    <div class="registration-container bold">
      <Field
        type="text"
        name="registration"
        class="form-control license-plate"
        id="vehicleRegistrationFilter"
        v-model="filters.registration"
        placeholder="Enter reg"
        maxlength="12"
        @input="filters.registration = filters.registration.toUpperCase()"
      />
    </div>
    <ErrorMessage name="registration" class="error-feedback" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { object, string } from "yup";

export default {
  name: "RegistrationFilter",
  components: { Field, ErrorMessage },
  data() {
    return {
      filters: {
        registration: "",
      },
      schema: object().shape({
        registration: string()
          .min(3, "Must be at least 3 characters!")
          .max(12, "Must not be more than 12 characters!")
          .nullable()

      }),
    };
  },
  methods: {
    handleInput() {
      this.filters.registration = this.filters.registration.toUpperCase();
      this.$emit("update:registration", this.filters.registration);
    },
  },
  watch: {
    registration(newVal) {
      this.$emit("update:registration", newVal);
    },
  },
};
</script>

<style scoped>
.registration-container {
  display: flex;
  align-items: center;
  border: 1px solid #393946;
  border-radius: 4px;
  padding: 0 10px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.registration-container:focus-within {
  border-color: #2269f5;
  box-shadow: 0 0 5px 2px rgba(34, 105, 245, 0.3);
}

.eu-flag {
  font-size: 1.5em;
  padding-right: 5px;
  position: relative;
  color: #000;
}

.gb-text {
  display: block;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  margin-top: -4px;
}

@font-face {
  font-family: "UK Number Plate";
  src: url("../assets/UKNumberPlate.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.license-plate {
  border: none;
  font-family: "UK Number Plate", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  flex: 1;
  font-size: 1.25rem;
  color: #adadad;
  padding: 9px 18px;
  background-color: transparent;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.license-plate::placeholder {
  color: #adadad;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.license-plate:focus::placeholder {
  opacity: 0.5;
}

.license-plate:focus {
  outline: none;
  background-color: none;
  box-shadow: none;
}
</style>
