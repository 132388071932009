<template>
  <div class="container mt-5">
    <div class="row mb-4">
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="mb-0">Orders</h5>
          </div>
          <div class="card-body">
            <p class="card-text">View and manage your orders</p>
            <div class="d-flex justify-content-between">
              <button class="btn btn-sm btn-outline-primary" @click="fetchOrders">Refresh Orders</button>
              <!-- <button class="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#addOrderModal">Add
                Order</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Controls at the Top -->
    <div class="row mb-4">
      <div class="col text-center">
        <!-- Add Status Filter Dropdown -->
        <select v-model="selectedStatus" @change="fetchOrders" class="form-select d-inline-block w-auto me-2">
          <option value="">All Statuses</option>
          <option v-for="(color, status) in statusColors" :key="status" :value="status">
            {{ status.charAt(0).toUpperCase() + status.slice(1) }}
          </option>
        </select>
        <button class="btn btn-outline-primary" @click="prevPage" :disabled="pagination.page === 1">Previous</button>
        <span>Page {{ pagination.page }} of {{ pagination.lastPage }}</span>
        <button class="btn btn-outline-primary" @click="nextPage"
          :disabled="pagination.page === pagination.lastPage">Next</button>
        <input type="number" v-model.number="pagination.page" @change="fetchOrders" min="1" :max="pagination.lastPage"
          class="page-input form-control d-inline-block">
      </div>
    </div>

    <div v-if="orders.length > 0" class="row">
      <div class="col">
        <div class="card">
          <div class="card-header text-center">
            <h5>{{ pagination.total }} orders found</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="order in orders" :key="order.order_id" class="col-md-4 mb-4">
        <div class="card">
          <div class="card-header">
            <h5>Order #{{ order.order_id }}</h5>
          </div>
          <div class="card-body">
            <p>Order Placed By: {{ order.admin_user_email }}</p>
            <p>Order Fulfilled By: {{ order.fulfillment_emails[0] }}</p>
            <p>Estimated Completion Date: {{ formatDate(order.estimated_completion_date) }}</p>
            <p>Total: £{{ order.total_price }}</p>
            <p>
              <span :style="{ backgroundColor: statusColors[order.status] }" class="status-square"></span>
              Status: {{ order.status }}
            </p>
            <p>Created At: {{ formatDate(order.order_date) }}</p>
            <p>Email Sent: {{ order.email_sent ? 'Yes' : 'No' }}</p>
            <div v-for="item in order.order_items" :key="item.order_item_id" class="product-details">
              <p>Product ID: {{ item.product_id }}</p>
              <p>Product: {{ item.product.name }}</p>
              <p>Product Description: {{ item.product.description }}</p>
              <p>Quantity: {{ item.quantity }}</p>
              <p>Price: £{{ item.price }}</p>
              <p>Subtotal: £{{ item.subtotal }}</p>
            </div>
            <button class="btn btn-sm btn-warning float-end me-2" @click="toggleStatusDropdown(order.order_id)">Change
              Status</button>
            <div v-if="order.showStatusDropdown" class="status-dropdown">
              <select v-model="order.newStatus" @change="changeOrderStatus(order.order_id, order.newStatus)">
                <option v-for="status in getStatusOptions(order.status)" :key="status" :value="status">
                  {{ status }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Controls at the Bottom -->
    <div class="row mt-4">
      <div class="col text-center">
        <button class="btn btn-outline-primary" @click="prevPage" :disabled="pagination.page === 1">Previous</button>
        <span>Page {{ pagination.page }} of {{ pagination.lastPage }}</span>
        <button class="btn btn-outline-primary" @click="nextPage"
          :disabled="pagination.page === pagination.lastPage">Next</button>
        <input type="number" v-model.number="pagination.page" @change="fetchOrders" min="1" :max="pagination.lastPage"
          class="page-input form-control d-inline-block">
      </div>
    </div>

    <!-- Add Order Modal -->
    <!-- <div class="modal fade" id="addOrderModal" tabindex="-1" aria-labelledby="addOrderModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addOrderModalLabel">Add Order</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="productSelect" class="form-label">Products</label>
              <div v-for="(product, index) in newOrder.products" :key="index" class="mb-3">
                <select class="form-select" v-model="product.product_id" @change="updateProductDetails(index)">
                  <option v-for="productOption in availableProducts" :key="productOption.product_id"
                    :value="productOption.product_id">
                    {{ productOption.name }} (Stock: {{ productOption.stock_count }})
                  </option>
                  <option :value="product.product_id" v-if="product.product_id">
                    {{ product.name }}
                  </option>
                </select>
                <p class="mt-2">Selected Product: {{ product.name }}</p> -->
                <!-- Display the selected product name -->
                <!-- <input type="number" class="form-control mt-2" v-model.number="product.quantity"
                  @input="updateProductDetails(index)" :max="getProductStock(product.product_id)" min="1"
                  placeholder="Quantity">
                <p class="mt-2">Price: £{{ product.price }}</p>
                <p>Subtotal: £{{ product.subtotal }}</p>
                <button class="btn btn-danger btn-sm mt-2" @click="removeProduct(index)">Remove</button>
              </div>
              <button class="btn btn-primary btn-sm" @click="addProduct">Add Another Product</button>
            </div>
            <div class="mb-3">
              <h5>Total: £{{ calculateTotal }}</h5>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="addOrder">Add Order</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from "../../services/auth-header";

export default {
  name: 'Ordering',
  data() {
    return {
      orders: [],
      // products: [],
      loading: false,
      // newOrder: {
      //   products: []
      // },
      pagination: {
        page: 1,
        limit: 25,
        total: 0,
        lastPage: 1
      },
      selectedStatus: ''
    }
  },
  computed: {
    statusColors() {
      return {
        'pending': '#ffeb3b', // Light Yellow
        'processing': '#03a9f4', // Light Blue
        'shipped': '#4caf50', // Light Green
        'completed': '#f44336' // Light Red
      };
    },
    // calculateTotal() {
    //   return this.newOrder.products.reduce((total, product) => total + parseFloat(product.subtotal), 0).toFixed(2);
    // },
    // availableProducts() {
    //   const selectedProductIds = this.newOrder.products.map(product => product.product_id);
    //   return this.products.filter(product => !selectedProductIds.includes(product.product_id));
    // }
  },
  mounted() {
    this.fetchOrders();
    // this.fetchProducts();
  },
  methods: {
    fetchOrders() {
      this.loading = true;
      const params = {
        page: this.pagination.page,
        limit: this.pagination.limit
      };
      if (this.selectedStatus) {
        params.status = this.selectedStatus;
      }
      axios.get('https://api.varsanpr.com/api/ordering-pos/orders-pos', {
        params,
        headers: authHeader()
      })
        .then(response => {
          this.orders = response.data.data;
          this.pagination.total = response.data.total;
          this.pagination.lastPage = Math.ceil(response.data.total / this.pagination.limit);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    // fetchProducts() {
    //   axios.get('https://api.varsanpr.com/api/ordering-pos/products-pos', {
    //     headers: authHeader()
    //   })
    //     .then(response => {
    //       this.products = response.data.data;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    // addOrder() {
    //   // Validation
    //   if (!this.validateNewOrder(this.newOrder)) {
    //     return;
    //   }

    //   const orderData = {
    //     order_items: this.newOrder.products.map(product => ({
    //       product_id: product.product_id,
    //       quantity: product.quantity
    //     }))
    //   };

    //   axios.post('https://api.varsanpr.com/api/ordering-pos/orders-pos', orderData, {
    //     headers: authHeader()
    //   })
    //     .then(() => {
    //       this.fetchOrders();
    //       this.resetNewOrderForm();
    //       this.fetchProducts();
    //       const modal = bootstrap.Modal.getInstance(document.getElementById('addOrderModal'));
    //       modal.hide();
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    // validateNewOrder(order) {
    //   if (order.products.length === 0) {
    //     this.$error('At least one product must be added.');
    //     return false;
    //   }
    //   for (const product of order.products) {
    //     if (!product.product_id || product.quantity <= 0 || product.quantity > this.getProductStock(product.product_id)) {
    //       this.$error('Each product must have a valid quantity within the available stock.');
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    // resetNewOrderForm() {
    //   this.newOrder = {
    //     products: []
    //   };
    // },
    // updateProductDetails(index) {
    //   const product = this.newOrder.products[index];
    //   const selectedProduct = this.products.find(p => p.product_id === product.product_id);
    //   if (selectedProduct) {
    //     product.name = selectedProduct.name;
    //     product.price = selectedProduct.price;
    //     product.subtotal = (product.price * product.quantity).toFixed(2);
    //     if (product.quantity > selectedProduct.stock_count) {
    //       product.quantity = selectedProduct.stock_count;
    //       product.subtotal = (product.price * product.quantity).toFixed(2);
    //     }
    //   }
    // },
    // addProduct() {
    //   this.newOrder.products.push({
    //     product_id: null,
    //     quantity: 1,
    //     price: 0,
    //     subtotal: 0
    //   });
    // },
    // removeProduct(index) {
    //   this.newOrder.products.splice(index, 1);
    // },
    // getProductStock(productId) {
    //   const product = this.products.find(p => p.product_id === productId);
    //   return product ? product.stock_count : 0;
    // },
    // getProductById(productId) {
    //   return this.products.find(p => p.product_id === productId) || {};
    // },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    toggleStatusDropdown(orderId) {
      const order = this.orders.find(o => o.order_id === orderId);
      order.showStatusDropdown = !order.showStatusDropdown;
    },
    getStatusOptions(currentStatus) {
      const allStatuses = ['pending', 'processing', 'shipped', 'completed'];
      return allStatuses.filter(status => status !== currentStatus);
    },
    changeOrderStatus(orderId, newStatus) {
      axios.put(`https://api.varsanpr.com/api/ordering-pos/orders-pos/${orderId}`, { status: newStatus }, {
        headers: authHeader()
      })
        .then(() => {
          this.fetchOrders();
        })
        .catch(error => {
          console.log(error);
        });
    },
    nextPage() {
      if (this.pagination.page < this.pagination.lastPage) {
        this.pagination.page++;
        this.fetchOrders();
      }
    },
    prevPage() {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.fetchOrders();
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.card-header {
  background-color: #007bff;
  color: white;
}

.card-body {
  padding: 20px;
}

.btn {
  margin: 5px;
}

.product-details {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.status-badge {
  padding: 5px;
  border-radius: 5px;
  color: white;
}

.status-square {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 2px;
}

.status-dropdown {
  margin-top: 10px;
}

.page-input {
  width: 60px;
  display: inline-block;
  margin-left: 10px;
  padding: 5px;
  text-align: center;
}
</style>