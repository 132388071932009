<template>
  <div class="row m-t-xs">
    <div class="col-12">
      <div class="card widget widget-list m-b-xxl">
        <div class="card-header">
          <h1 class="m-b-xs section-heading">
            Alert: {{ this.alert.id }}
          </h1>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row m-t-xs">
    <div class="col-6">
      <div class="form-control image-container">
        <h2>New Alert Image</h2>
        <div class="image-wrapper">
          <img
            v-if="alert.new_image"
            :src="alert.new_image"
            alt="Alert Image"
            class="img-fluid alert_image"
            oncontextmenu="return false;"
          />
          <div v-else>
            <p style="color: red; font-style: italic;">IMAGE NOT AVAILABLE</p>
          </div>
        </div>
        <div style="text-align: center; font-style: italic;">
          <p>This image is only available for 7 days after the alert has been generated.</p>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="form-control image-container">
        <h2>Incident Image</h2>
        <div class="image-wrapper">
          <img
            v-if="alert.old_image"
            :src="alert.old_image"
            alt="Incident Image"
            class="img-fluid alert_image"
            oncontextmenu="return false;"
          />
          <div v-else>
            <p>Image not available</p>
          </div>
        </div>
      </div>
    </div>
  </div><br>
  <div class="row m-t-xs">
    <div class="col-12">
      <div class="form-control">
        <h2>Alert Information:</h2>
        <div class="card-body">
          <table class="table" v-if="this.alert.id !== -1">
            <thead>
              <tr>
                <th>ID</th>
                <th>Site</th>
                <th>Date/Time</th>
                <th>Similarity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ this.alert.id }}</td>
                <td>{{ siteName }}</td>
                <td>{{ convertDate(this.alert.alerted_at) }}</td>
                <td>{{ this.alert.similarity && this.alert.similarity.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";

export default {
  name: 'Alert',
  data() {
    return {
      loading: false,
      alert: {
        id: -1,
        site_id: -1,
      },
      siteName: '',
      alert_incidents: {},
    }
  },
  async mounted() {
    this.alert.id = this.$route.params.id
    this.alert.site_id = this.$route.params.site_id

    await this.loadSites();
    this.loadAlert();
  },
  methods: {
    async loadSites() {
      try {
        const response = await ClientService.getClientSites(
          this.$store.state.auth.user.selectedClient,
          null,
          null,
          5
        );
        const sites = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        console.log(sites);

        const site = sites.find(site => site.id === String(this.alert.site_id));
        this.siteName = site ? site.name : 'Unknown';
      } catch (error) {
        console.error("Error loading sites:", error);
      }
    },
    loadAlert() {
      this.loading = true;

      axios.get(`https://api.varsanpr.com/customer/alert/portalAlerts/${this.alert.site_id}/${this.alert.id}`, {
        headers: authHeader()
      })
      .then((response) => {
        this.alert = response.data.alert;
        this.alert_incidents = response.data.alert_incidents;
      })
      .catch((error) => {
        this.$error('Failed to load alert', error);
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    convertDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    },
  },
  computed: {
    incidentSite() {
      if (this.alert.site_id !== this.alert_incidents.site_id) {
        return "External Site";
      } else {
        return this.siteName;
      }
    }
  }
}
</script>

<style scoped>

.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.alert_image {
    width: 65%;
    height: auto;
    display: block;
    margin: 0 auto;
}

</style>