<template>
  <div class="row">
    <div class="col-12">
      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filters"
              aria-expanded="false"
              aria-controls="filters"
            >
              {{ $t("events.filters.title") }} ({{
                $t("events.filters.click_to_open")
              }})
            </button>
          </h2>

          <!-- Filter  -->
          <div
            id="filters"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#filterAccordion"
          >
            <div class="accordion-body">
              <Form
                @submit="(values) => loadEvents(pagination.page, values)"
                :validation-schema="schema"
                ref="eventsForm"
              >
                <div class="row">
                  <RegistrationInput />
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="siteFilter" class="form-label">{{
                      $t("events.filters.site")
                    }}</label>
                    <Multiselect
                      :class="{ 'multi-dark': $store.state.auth.status.dark }"
                      mode="tags"
                      placeholder="Select a site"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="true"
                      :multiple="false"
                      :options="sites"
                      label="name"
                      track-by="id"
                      name="site"
                      @select="(value) => loadCameras(value?.id || null)"
                      @remove="loadCameras()"
                      v-model="filters.site"
                    />
                    <ErrorMessage name="site" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="cameraFilter" class="form-label">{{
                      $t("events.filters.camera")
                    }}</label>
                    <Multiselect
                      :class="{ 'multi-dark': $store.state.auth.status.dark }"
                      mode="tags"
                      placeholder="Select a camera"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="true"
                      :multiple="false"
                      :options="cameras"
                      label="name"
                      track-by="id"
                      name="camera"
                      id="cameraFilter"
                      v-model="filters.camera"
                    />
                    <ErrorMessage name="camera" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="directionFilter" class="form-label">{{
                      $t("events.filters.direction")
                    }}</label>
                    <Field
                      id="directionFilter"
                      class="form-select"
                      name="direction"
                      as="select"
                      v-model="filters.direction"
                    >
                      <option value="" selected disabled>
                        {{ $t("events.filters.select_a_direction") }}
                      </option>
                      <option value="reverse">
                        {{ $t("events.filters.leaving_site") }}
                      </option>
                      <option value="forward">
                        {{ $t("events.filters.entering_site") }}
                      </option>
                    </Field>
                    <ErrorMessage name="direction" class="error-feedback" />
                  </div>
                </div>
                <div class="row m-t-xs">
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="startDateFilter" class="form-label">{{
                      $t("events.filters.start_date")
                    }}</label>
                    <Field
                      type="date"
                      name="start_date"
                      class="form-control"
                      id="startDateFilter"
                      v-model="filters.start_date"
                    />
                    <ErrorMessage name="start_date" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="endDateFilter" class="form-label">{{
                      $t("events.filters.end_date")
                    }}</label>
                    <Field
                      type="date"
                      name="end_date"
                      class="form-control"
                      id="endDateFilter"
                      v-model="filters.end_date"
                    />
                    <ErrorMessage name="end_date" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="startTimeFilter" class="form-label">{{
                      $t("events.filters.start_time")
                    }}</label>
                    <Field
                      type="time"
                      name="start_time"
                      class="form-control"
                      id="startTimeFilter"
                      v-model="filters.start_time"
                    />
                    <ErrorMessage name="start_time" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="endTimeFilter" class="form-label">{{
                      $t("events.filters.end_time")
                    }}</label>
                    <Field
                      type="time"
                      name="end_time"
                      class="form-control"
                      id="endTimeFilter"
                      v-model="filters.end_time"
                    />
                    <ErrorMessage name="end_time" class="error-feedback" />
                  </div>
                </div>
                <div class="row m-t-xs">
                  <!-- Disabled due to performance issues (make/model/colour filters) -->

                  <!-- <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="makeFilter" class="form-label">
                      {{ $t("events.filters.make") }}
                    </label>
                    <Field
                      name="make"
                      as="select"
                      class="form-select"
                      id="makeFilter"
                      v-model="filters.make"
                    >
                      <option value="" selected disabled>
                        {{ $t("events.filters.select_a_make") }}
                      </option>
                      <option
                        v-for="make in CAR_MAKES"
                        :key="make.value"
                        :value="make.value"
                      >
                        {{ make.label }}
                      </option>
                    </Field>
                    <ErrorMessage name="make" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="modelFilter" class="form-label">
                      {{ $t("events.filters.model") }}
                    </label>
                    <Field
                      name="model"
                      as="select"
                      class="form-select"
                      id="modelFilter"
                      v-model="filters.model"
                      :disabled="!filters.make"
                    >
                      <option value="" selected disabled>
                        {{ $t("events.filters.select_a_model") }}
                      </option>
                      <option
                        v-for="model in carModels"
                        :key="model.value"
                        :value="model.value"
                      >
                        {{ model.label }}
                      </option>
                    </Field>
                    <ErrorMessage name="model" class="error-feedback" />
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="colourFilter" class="form-label">
                      {{ $t("events.filters.colour") }}
                    </label>
                    <Field
                      name="colour"
                      as="select"
                      class="form-select"
                      id="colourFilter"
                      v-model="filters.colour"
                    >
                      <option value="" selected disabled>
                        {{ $t("events.filters.select_a_colour") }}
                      </option>
                      <option
                        v-for="colour in COLOURS"
                        :key="colour.value"
                        :value="colour.value"
                      >
                        {{ colour.label }}
                      </option>
                    </Field>
                    <ErrorMessage name="colour" class="error-feedback" />
                  </div> -->
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="statusFilter" class="form-label">{{
                      $t("events.filters.status")
                    }}</label>
                    <Field
                      name="status"
                      class="form-select"
                      id="statusFilter"
                      v-model="filters.status"
                      as="select"
                    >
                      <option value="" selected disabled>
                        {{ $t("events.select_status") }}
                      </option>
                      <option value="blacklisted">
                        {{ $t("vehicle.blacklisted") }}
                      </option>
                      <option value="active">{{ $t("global.active") }}</option>
                    </Field>
                    <ErrorMessage name="status" class="error-feedback" />
                  </div>
                </div>
                <div class="row m-t-md" style="justify-content: center">
                  <div class="" style="width: auto">
                    <button
                      type="submit"
                      :disabled="loading"
                      class="btn btn-primary"
                      style="max-width: 150px"
                    >
                      <!-- Show spinning icon when dataTable is loading -->
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm m-r-xxs"
                      ></span>
                      <span>{{ $t("events.filters.search") }}</span>
                    </button>
                  </div>
                  <div class="col-12 width-auto" style="width: auto">
                    <button
                      type="reset"
                      :disabled="loading"
                      class="btn btn-secondary"
                      style="max-width: 150px"
                      @click="clearFilter"
                    >
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm m-r-xxs"
                      ></span>
                      <span>{{ $t("global.clear_filter") }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Heading -->
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
        "
      >
        <div
          style="display: flex; flex-wrap: wrap; align-items: center; gap: 10px"
        >
          <h1 class="table-heading" style="padding: 0; margin: 0">
            {{ $t("events.events") }}
            <span
              v-if="timeSinceLastEvent"
              style="font-size: 0.8em; color: #888; margin-left: 10px"
              >({{ timeSinceLastEvent }})
            </span>
          </h1>
          <div
            v-if="showRefreshPrompt"
            class="refresh-prompt"
            style="margin-right: 20px"
          >
            <p style="margin: 0; display: flex; align-items: center; gap: 10px">
              <button
                class="btn btn-primary"
                @click="refreshEvents"
                style="padding: 7px"
              >
                <i class="material-icons" style="margin: 0">refresh</i>
              </button>
              {{ newEventsCount }} new events
            </p>
          </div>
        </div>

        <!-- Pagination -->
        <Pagination
          v-if="!loading"
          :showTotal="false"
          :config="pagination"
          @search="(page) => loadEvents(page, currentParams)"
        />
      </div>

      <!-- Loading -->
      <div v-show="loading" style="width: 100%; text-align: center">
        <div
          class="spinner-border"
          style="height: 4rem; width: 4rem"
          role="status"
        >
          <span class="visually-hidden">{{ $t("events.loading") }}...</span>
        </div>
      </div>

      <!-- Event Card -->
      <div v-show="!loading" class="row events-container">
        <div v-show="events.length < 1" class="flex-center no-data-container">
          <p>No events</p>
        </div>
        <div
          v-for="event in events"
          :key="'events-' + event.id"
          class="card"
          :class="event.status === 'blacklisted' ? 'blacklist-card' : ''"
        >
          <div class="image-container">
            <!-- <EventImage 
                            v-if="event.image !== null"
                            :filename="event.image"
                            :site_id="event.site.id"
                            :id="'eventimage-' + event.id"
                            :thumb="false"
                            :storage="event.storage_account || 1"
                        /> -->
            <div
              class="image-container"
              :class="{ 'no-image': event.image === null }"
            >
              <SasEventImage
                v-if="event.image !== null"
                :src="event.image_url"
                :filename="event.image"
                :site_id="event.site.id"
                :id="'eventimage-' + event.id"
                :thumb="false"
                :storage="event.storage_account || 1"
              />
              <div v-else class="placeholder"></div>
            </div>
          </div>
          <div
            class="event-information-container"
            @click="$router.push(`/events/${event.id}`)"
          >
            <div class="information-sub-container">
              <span id="site-name"> {{ event.site.name + ":" }} </span>
              <!-- <div class="status-container"> <span class="status-container"> {{ event.status}} </span> </div> -->
              <!-- <span class="status-container"> {{ event.status.toUpperCase()}} </span> -->
              <span :class="event.status === 'active' ? 'green' : 'red'">
                {{ event.status.toUpperCase() }}
              </span>
            </div>
            <div class="information-sub-container">
              <span id="camera-name"> {{ event.camera.name }} </span>
            </div>
            <div class="information-sub-container">
              <span id="camera-direction">
                {{
                  event.direction === "reverse"
                    ? this.$t("events.filters.leaving_site")
                    : this.$t("events.filters.entering_site")
                }}
              </span>
              <Timestamp
                :time="event.created_at"
                format="dd/MM/yyyy HH:mm:ss"
                id="timestamp"
              />
            </div>
            <div class="information-sub-container btn-container">
              <span
                class="vrm bold number-plate-gbr"
                style="font-size: 16px"
                @click.stop.prevent="copyToClipboard(event.vehicle.plate)"
                >{{ event.vehicle.plate }}</span
              >
              <button
                v-if="event.status === 'active' && $can('events-blacklist')"
                class="btn btn-primary bl-btn"
                @click.stop="openModal(event)"
              >
                <i class="material-icons-outlined"> add</i>
                {{ $t("blacklist.add_to_blacklist") }}
              </button>
              <button
                v-if="
                  event.status === 'blacklisted' &&
                  $can('events-unblacklist') &&
                  event.blacklist_count > 0
                "
                class="btn btn-primary secondary-blue-btn"
                @click.stop="openUnblacklistModal(event)"
              >
                <i class="material-icons-outlined"> close </i>
                {{ $t("blacklist.remove_from_blacklist") }}
              </button>
              <span
                v-if="
                  event.status === 'blacklisted' &&
                  $can('events-unblacklist') &&
                  event.blacklist_count < 1
                "
                class="btn btn-success btn-outlined"
                style="cursor: default"
                v-tooltip="
                  'This vehicle is on the national blacklist, it must be removed by the company that blacklisted it.'
                "
                >National Blacklist</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <Pagination
        v-if="!loading && events.length > 0"
        :showTotal="false"
        :config="pagination"
        @search="(page) => loadEvents(page, currentParams)"
      />
    </div>
  </div>

  <teleport to="body">
    <div
      class="modal fade"
      id="eventImageModal"
      tabindex="-1"
      style="display: none; padding-right: 17px"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="height: 80vh; width: 75%; max-width: 75%"
      >
        <div class="img-magnifier-container">
          <!-- <EventImage
                    v-if="currentEvent !== null" 
                    :thumb="false" :filename="currentEvent.image" 
                    :site_id="currentEvent.site.id" 
                    :id="'modaleventimage-' + currentEvent.id"
                /> -->
          <SasEventImage
            v-if="currentEvent !== null"
            :thumb="false"
            :filename="currentEvent.image"
            :site_id="currentEvent.site.id"
            :id="'modaleventimage-' + currentEvent.id"
            :src="currentEvent.image_url"
          />
        </div>
        <p class="closePopup">
          {{ $t("events.press") }} <code>ESC</code>
          {{ $t("events.event_close") }}
        </p>
      </div>
    </div>
  </teleport>

  <!-- Add to Blacklist modal -->
  <div
    class="modal fade"
    id="addToBlacklistModal"
    tabindex="-1"
    aria-labelledby="addToBlacklistTitle"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 v-show="currentEvent !== null" class="m-r-sm textAlign-center">
            {{ $t("blacklist.add_to_blacklist") }}
          </h4>
          <p class="modal-sub-title">
            {{ $t("blacklist.blacklist_modal_message") }}
            {{ currentEvent?.vehicle.plate }}
          </p>
          <select class="form-select m-b-md" v-model="reason">
            <option value="" selected>Select A Reason</option>
            <option
              v-for="reason in blacklistReasons"
              :value="reason"
              :key="reason"
            >
              {{ reason }}
            </option>
          </select>
          <div class="flex-center">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => addToBlacklistModal.hide()"
            >
              {{ $t("global.close") }}
            </button>
            <button
              class="btn btn-primary bg-danger"
              style="border-color: #dc3545"
              @click="addToBlacklist"
            >
              <i class="material-icons-outlined"> add </i>
              {{ $t("blacklist.add_to_blacklist") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Remove from Blacklist Modal -->
  <div
    class="modal fade"
    id="unblacklistModal"
    tabindex="-1"
    aria-labelledby="unblacklistModal"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 v-show="currentEvent !== null" class="m-r-sm textAlign-center">
            {{ $t("blacklist.remove_from_blacklist") }}
          </h4>
        </div>
        <div class="flex p-v-md">
          <i class="material-icons warning-icon m-r-sm"> warning_amber </i>
          <div>
            <h4 class="text-center">{{ $t("blacklist.are_you_sure") }} ?</h4>
            <span> {{ $t("blacklist.modal_message") }} </span>
          </div>
        </div>
        <div class="flex-center">
          <button
            class="btn btn-secondary m-r-sm"
            @click="() => unblacklistModal.hide()"
          >
            {{ $t("global.cancel") }}
          </button>
          <button class="btn btn-primary" @click="unblacklist">
            <i class="material-icons-outlined"> close </i>
            {{ $t("blacklist.remove_from_blacklist") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "../../components/Pagination.vue";
// import EventImage from '../../components/EventImage.vue';
import SasEventImage from "../../components/SasEventImage.vue";
import Timestamp from "../../components/Timestamp.vue";
import RegistrationInput from "../../components/RegistrationInput.vue";
import { Multiselect } from "vue-multiselect";

//Dependencies
import * as yup from "yup";
import CameraService from "../../services/camera.service";
import ClientService from "../../services/client.service";
import eventService from "../../services/event.service";
import { CAR_MAKES, COLOURS } from "../../constants/constants";

export default {
  name: "Events",
  components: {
    Form,
    Field,
    ErrorMessage,
    RegistrationInput,
    Pagination,
    Timestamp,
    Multiselect,
    SasEventImage,
  },
  props: {
    registration: String,
    site_id: String | Number,
    start_date: String,
    end_date: String,
    start_time: String,
    end_time: String,
    direction: String,
    status: String,
    site: Object,
  },
  data() {
    const schema = yup.object().shape({
      start_date: yup
        .date()
        .max(new Date("9999-12-31"), "Surpassed year limit, Please check Date.")
        .optional()
        .nullable(),
      end_date: yup
        .date()
        .max(new Date("9999-12-31"), "Surpassed year limit, Please check Date.")
        .optional()
        .nullable(),
      start_time: yup
        .string()
        .matches(
          "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$",
          "Must be a valid HH:mm time"
        )
        .optional()
        .nullable(),
      end_time: yup
        .string()
        .matches(
          "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$",
          "Must be a valid HH:mm time"
        )
        .optional()
        .nullable(),
      direction: yup.string().optional().nullable(),
      status: yup.string().optional().nullable(),
    });
    return {
      loading: true,
      events: [],
      newEventsCount: 0,
      pollingInterval: null,
      showRefreshPrompt: false,
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      schema,
      CAR_MAKES,
      COLOURS,
      filters: {
        registration: null,
        site: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        direction: null,
        status: null,
        camera: null,
        make: null,
        model: null,
        colour: null,
      },
      sites: [],
      cameras: [],
      search: {
        client_id: null,
      },
      currentParams: {},
      currentEvent: null,
      clientID: this.$store.state.auth.user.selectedClient,
      addToBlacklistModal: null,
      unblacklistModal: null,
      blacklistReasons: [
        "Drive Off",
        "No Means of Payment",
        "Fly Tipping",
        "Theft",
        "Aggressive",
        "Plate Mismatch",
      ],
      reason: "",
    };
  },
  mounted() {
    // this.loadEvents();

    let previousRoute = this.$router.options.history.state.forward;
    if (!previousRoute) previousRoute = this.$router.options.history.state.back;
    console.log(previousRoute);
    if (previousRoute) {
      if (previousRoute.includes("/events/")) {
        console.log("Previous route was probably an event");
      } else {
        console.log("Previous route was not an event");
        localStorage.removeItem("varsportal::eventsParams::" + this.clientID);
        localStorage.removeItem(
          "varsportal::eventsPagination::" + this.clientID
        );
      }
    }

    this.loadSites();
    this.loadCameras();
    this.addToBlacklistModal = new bootstrap.Modal(
      document.getElementById("addToBlacklistModal")
    );
    this.unblacklistModal = new bootstrap.Modal(
      document.getElementById("unblacklistModal")
    );

    if (this.isValid(this.status)) {
      this.filters.status = this.status;
    }
    if (this.isValid(this.start_date)) {
      this.filters.start_date = this.start_date;
    }
    if (this.isValid(this.end_date)) {
      this.filters.end_date = this.end_date;
    }
    if (this.isValid(this.start_time)) {
      this.filters.start_time = this.start_time;
    }
    if (this.isValid(this.end_time)) {
      this.filters.end_time = this.end_time;
    }
    if (this.isValid(this.site)) {
      let site = JSON.parse(this.site);
      if (this.sites.length > 0) {
        this.filters.site = this.sites.find(
          (site) => parseInt(site.id) === parseInt(site.id)
        );
      } else {
        this.filters.site = site;
      }
    }

    if (this.status && this.start_date && this.end_date) {
      this.loadEvents(1, this.filters);
      return;
    }
    let params = JSON.parse(
      localStorage.getItem("varsportal::eventsParams::" + this.clientID)
    );
    let pagination = JSON.parse(
      localStorage.getItem("varsportal::eventsPagination::" + this.clientID)
    );
    if (pagination) {
      this.pagination = pagination;
    }
    if (params) {
      this.filters = params;
      this.loadEvents(this.pagination.page || 1, params);
      return;
    }
    this.loadEvents();
    this.pollingInterval = setInterval(this.checkForNewEvents, 60000);
  },
  computed: {
    carModels() {
      const selectedMake = this.CAR_MAKES.find(
        (make) => make.value === this.filters.make
      );
      return selectedMake && selectedMake.models ? selectedMake.models : [];
    },
    timeSinceLastEvent() {
      if (!this.events || this.events.length === 0) return null;
      const lastEventTime = Math.max(
        ...this.events.map((event) => event.created_at)
      );
      const currentTime = Math.floor(Date.now() / 1000);
      const timeDifference = currentTime - lastEventTime;
      const secondsInHour = 3600;

      if (timeDifference <= secondsInHour) return null;
      const secondsInDay = 86400;
      const secondsInMonth = 2592000;

      if (timeDifference >= secondsInMonth) {
        const months = Math.floor(timeDifference / secondsInMonth);
        return `last event ${months} month${months > 1 ? "s" : ""} ago`;
      }

      if (timeDifference >= secondsInDay) {
        const days = Math.floor(timeDifference / secondsInDay);
        return `last event ${days} day${days > 1 ? "s" : ""} ago`;
      }

      const hours = Math.floor(timeDifference / secondsInHour);
      return `last event ${hours} hour${hours > 1 ? "s" : ""} ago`;
    },
  },
  methods: {
    modalImageLoaded() {
      console.log("Modal image loaded");
    },
    async loadEvents(page = 1, params = {}) {
      this.loading = true;
      this.currentParams = params;

      params.site = this.filters.site?.id || null;
      params.camera = this.filters.camera?.id || null;
      params.make = this.filters.make || null;
      params.model = this.filters.model || null;
      params.colour = this.filters.colour || null;

      try {
        const response = await eventService.getEvents(
          page,
          this.clientID,
          params
        );
        this.events = response.data.events;
        this.pagination = response.data.pagination;
        this.loading = false;

        localStorage.setItem(
          "varsportal::eventsParams::" + this.clientID,
          JSON.stringify(this.currentParams)
        );
        localStorage.setItem(
          "varsportal::eventsPagination::" + this.clientID,
          JSON.stringify(this.pagination)
        );
      } catch (error) {
        this.$error(this.$t("events.events_error", error));
        this.loading = false;
      }
    },
    async checkForNewEvents() {
      try {
        const params = { ...this.currentParams };
        const response = await eventService.getEvents(1, this.clientID, params);

        const newEvents = response.data.events;
        const newEventCount = newEvents.filter(
          (newEvent) =>
            !this.events.some((currentEvent) => currentEvent.id === newEvent.id)
        ).length;

        if (newEventCount > 0) {
          this.newEventsCount = newEventCount;
          this.showRefreshPrompt = true;
        }
      } catch (error) {
        console.error("Error checking for new events:", error);
      }
    },
    async refreshEvents() {
      this.showRefreshPrompt = false;
      await this.loadEvents(1, this.currentParams);
      this.newEventsCount = 0;
    },
    beforeDestroy() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
    },
    loadSites() {
      ClientService.getClientSites(
        this.$store.state.auth.user.selectedClient,
        true
      )
        .then((response) => {
          this.sites = response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          this.sites.forEach((site) => {
            if (site.customer_reference) {
              site.name = "(" + site.customer_reference + ") " + site.name;
            }
          });
          if (this.filters.site?.id) {
            this.filters.site = this.sites.find(
              (site) => parseInt(site.id) === parseInt(this.filters.site.id)
            );
          }
        })
        .catch((error) => {
          this.$error(this.$t("sites.site_load_error"), error);
        });
    },
    loadCameras(site = null) {
      CameraService.getCameras(site)
        .then((response) => {
          this.cameras = response.data.cameras;
        })
        .catch((error) => {
          this.$error(this.$t("event.camera_error"), error);
        });
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$success(`Copied ${text} to clipboard`);
        })
        .catch((error) => {
          console.error("Clipboard copy failed", error);
          this.$error("Failed to copy to clipboard");
        });
    },
    clearFilter() {
      // resetForm();
      // this.loadEvents(1, {});
      this.filters = {
        registration: null,
        site: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        direction: null,
        status: null,
        camera: null,
        make: null,
        model: null,
        colour: null,
      };
    },
    openEventImage(event) {
      this.currentEvent = event;
      $("#eventImageModal").modal("toggle");
      // console.log(document.getElementById(`eventimage-${this.currentEvent.id}`));
      // this.magnify(3);
    },
    openModal(event) {
      this.currentEvent = event;
      this.addToBlacklistModal.show();
    },
    addToBlacklist() {
      if (this.reason) {
        eventService
          .blacklist(
            this.currentEvent.site.id,
            this.currentEvent.id,
            this.reason
          )
          .then((response) => {
            console.log(response);
            this.currentEvent.status = "blacklisted";
            this.events.forEach((event) => {
              if (event.vehicle.plate === this.currentEvent.vehicle.plate) {
                event.status = "blacklisted";
              }
            });
            this.$success(
              `Added ${this.currentEvent.vehicle.plate} to blacklist.`
            );
            this.addToBlacklistModal.hide();
          })
          .catch((error) => {
            console.log(error);
            this.$error(this.$t("blacklist.error_message"), error);
          });
      }
    },
    unblacklist() {
      eventService
        .unblacklist(this.currentEvent.site.id, this.currentEvent.id)
        .then((response) => {
          this.currentEvent.status = "active";
          this.events.forEach((event) => {
            if (event.vehicle.plate === this.currentEvent.vehicle.plate) {
              event.status = "active";
            }
          });
          this.$success(this.$t("blacklist.unblacklist_success"));
        })
        .catch((error) => {
          this.$error(this.$t("blacklist.unblacklist_error"), error);
        })
        .finally(() => {
          this.unblacklistModal.hide();
        });
    },
    openUnblacklistModal(event) {
      this.currentEvent = event;
      this.unblacklistModal.show();
    },
    isValid(value) {
      if (value === null) return false;
      if (value === undefined) return false;

      if (typeof value === "string") {
        if (value.length < 1) return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.events-container {
  gap: 50px;
  justify-content: center;
}

.card {
  width: 400px;
  height: 415px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
}

.image-container {
  margin: 10px 0;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container:hover {
  transform: scale(0.95);
}

.image-container::before {
  content: "\e8ff";
  font-family: "Material Icons";
  font-size: 36px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(22, 22, 22, 0.384);
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.image-container:hover::before {
  opacity: 1;
}

.event-information-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.event-information-container div {
  margin: 5px 0;
  align-items: center;
}

#site-name {
  font-size: 18px;
  font-weight: 500;
}

#camera-name,
#camera-direction,
#camera-direction,
#timestamp {
  font-size: 16px;
}

.information-sub-container {
  display: flex;
  justify-content: space-between;
}

.status-container {
  border: 2px solid var(--utility-green);
  border-radius: 50px;
  padding: 5px 10px;
  color: var(--utility-green);
  font-weight: 400;
}

/* .information-sub-container button {
    background-color: rgba(220, 53, 69, 0.3);
    border: 2px solid transparent;
    color: var(--utility-red);
} */

.information-sub-container button:hover {
  border: 2px solid var(--utility-red);
}

.btn-container {
  margin-top: 10px !important;
}

.red {
  color: var(--utility-red);
  background-color: rgba(220, 53, 69, 0.3);
}

.green {
  color: var(--utility-green);
  background-color: rgba(75, 173, 72, 0.3);
}

.red,
.green {
  border-radius: 50px;
  padding: 5px 10px;
  font-weight: 600;
}

/* .secondary-blue-btn {
    background-color: rgba(34, 105, 245, .3) !important;
    color: var(--utility-blue) !important;
} */

.secondary-blue-btn:hover {
  border-color: var(--utility-blue) !important;
}

.blacklist-card {
  border: 2px solid var(--utility-red);
}

.no-data-container {
  color: var(--utility-orange);
  font-style: italic;
  font-size: 16px;
  font-size: bold;
}

/* .modal-sub-title {
    font-weight: 100;
    color: #CDC4D1;
} */

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  width: 100px;
  height: 100px;
}

.warning-icon {
  font-size: 50px;
  color: var(--utility-orange);
}

.flex {
  flex-wrap: nowrap;
}

.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.placeholder::before {
  content: "No Image";
}
</style>
