<template>
  <div class="row">
    <div class="col-8">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            Incident Info - {{ incident?.status || "" }}
            <button
              class="btn btn-sm btn-outline-primary"
              @click="openResubmissionModal"
              v-if="incident?.status === 'AwaitingEvidence'"
            >
              Resubmit Incident
            </button>
            <button
              ref="editButton"
              :disabled="!incident?.id"
              v-if="incident?.status !== 'Closed'"
              @click="toggleEdit"
              class="btn btn-sm float-right"
              :class="{
                'btn-outline-primary': !editing,
                'btn-outline-danger': editing,
              }"
            >
              {{ editing ? "Cancel Edit" : "Edit Incident" }}
            </button>
            <button
              v-show="editing"
              :disabled="!incident?.id || saving"
              class="btn btn-sm btn-primary float-right m-r-sm"
              @click="saveChanges"
            >
              Save
            </button>
            <button
              v-show="!editing"
              :disabled="!incident?.id"
              v-if="
                incident?.status !== 'Closed' && incident?.status !== 'Rejected'
              "
              class="btn btn-sm btn-outline-danger float-right m-r-sm"
              @click="closure.modal.show()"
            >
              Request to Close
            </button>
          </h3>
        </div>
        <div class="card-body" v-if="incident?.id">
          <div
            v-show="editing"
            class="alert alert-custom alert-indicator-left indicator-warning mg-b-sm"
            role="alert"
          >
            <div class="alert-content">
              <span class="alert-title">Warning!</span>
              <span class="alert-text"
                >Any changes you make to the incident will be recorded for
                auditing purposes.</span
              >
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="incidentType">Incident Severity</label>
                <p>
                  <span :class="`sev-${severity}`"></span>
                  {{ severity.toLocaleUpperCase() }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="incidentType">Incident Type</label>
                <div v-show="!editing">
                  <p>
                    <span
                      v-for="type in types"
                      class="badge badge-warning m-r-xs"
                      >{{ type.name }}</span
                    >
                  </p>
                </div>
                <div v-show="editing">
                  <Multiselect
                    :class="{ 'multi-dark': $store.state.auth.status.dark }"
                    mode="tags"
                    placeholder="Select types"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :multiple="true"
                    :options="availableTypes"
                    label="name"
                    track-by="type_id"
                    name="types"
                    class="m-b-sm"
                    v-model="types"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="incidentType">Occurred</label>
                <div v-show="!editing">
                  <p>{{ incident_date }} : {{ incident_time }}</p>
                </div>
                <div v-show="editing">
                  <input
                    type="datetime-local"
                    class="form-control m-b-sm"
                    v-model="incident.happened_at"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="incidentType">Reported</label>
                <p>{{ created_date }} : {{ created_time }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Reported By</label>
                <p>{{ incident.created_by_user || "N/A" }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Police Force</label>
                <div v-show="!editing">
                  <p>{{ incident.police_force }}</p>
                </div>
                <div v-show="editing">
                  <input
                    type="text"
                    class="form-control m-b-sm"
                    v-model="incident.police_force"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Crime Reference</label>
                <div v-show="!editing">
                  <p>{{ incident.police_ref || "-" }}</p>
                </div>
                <div v-show="editing">
                  <input
                    type="text"
                    class="form-control m-b-sm"
                    v-model="incident.police_ref"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="incidentType">Description</label>
                <div v-show="!editing">
                  <p>
                    {{ incident.description || "No description provided" }}
                  </p>
                </div>
                <div v-show="editing">
                  <textarea
                    class="form-control m-b-sm"
                    rows="5"
                    v-model="incident.description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="wrapper">
            <div class="sub-wrapper">
              <Loader />
            </div>
          </div>
        </div>
      </div>

      <div class="card m-t-sm">
        <div class="card-header">
          <h3 class="card-title">Witnesses</h3>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="witness in witnesses">
                <td>{{ witness.witness }}</td>
                <td>{{ witness.type }}</td>
                <td>{{ witness.notes }}</td>
                <td>
                  <button
                    :disabled="witness.submitting"
                    class="btn btn-sm btn-danger"
                    @click="removeWitness(witness)"
                  >
                    Remove
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    :disabled="witness.submitting"
                    v-model="witness.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                  />
                </td>
                <td>
                  <select
                    :disabled="witness.submitting"
                    v-model="witness.type"
                    class="form-control"
                  >
                    <option>Staff</option>
                    <option>Public</option>
                    <option>Emergency Services</option>
                  </select>
                </td>
                <td>
                  <textarea
                    :disabled="witness.submitting"
                    v-model="witness.notes"
                    class="form-control"
                    rows="2"
                    placeholder="Notes"
                  ></textarea>
                </td>
                <td>
                  <button
                    :disabled="witness.submitting"
                    class="btn btn-sm btn-primary"
                    @click="addWitness"
                  >
                    Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card m-t-sm">
        <div class="card-header">
          <h3 class="card-title">
            Evidence
            <button
              :disabled="!incident?.id"
              class="btn btn-sm btn-outline-primary float-right"
              @click="awaitingEvidenceCheck()"
            >
              Upload Evidence
            </button>
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-4 m-b-sm" v-for="file in evidence">
              <div @mouseover="hoveredFileId = file.id" @mouseleave="hoveredFileId = null">
                <img
                  v-viewer
                  :src="file.url"
                  v-if="file.filetype === 'image'"
                  alt="evidence"
                  class="img-fluid"
                />

                <video
                  :src="file.url"
                  v-else-if="file.filetype === 'video'"
                  controls
                  class="img-fluid"
                ></video>
                
                <a target="_blank" 
                  :href="file.url" 
                  v-else 
                >{{ file.filename }}</a>

                <button 
                  class="btn btn-sm btn-primary" 
                  v-if="file.url === '' && hoveredFileId === file.id" 
                  @click="getReuploadLink(file.id)"
                >
                  Reupload Asset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-t-sm">
        <div class="card-header">
          <h3 class="card-title">Incident Notes</h3>
        </div>
        <div class="card-body" v-if="this.incidentNotes.length > 0" style="max-height: 350px; overflow-y: auto;">
          <div v-for="(note) in incidentNotes" 
            :key="note.id + 'note'" 
            class="note-item" 
            :class="{'own': note.user_id === $store.state.auth.user.id}"
            @mouseover="hoveredNote = note.id"
            @mouseleave="hoveredNote = null"
          >
            <div class="note-header">
              <strong>{{ note.email }}</strong>
              <span>{{ note.created_date }} : {{ note.created_time }}</span>
              <span v-if="note.user_id === $store.state.auth.user.id && hoveredNote === note.id" class="edit-icon" @click="toggleNoteEdit(note)">
                <span class="material-icons">edit</span>
              </span>
            </div>
            <div v-if="editedNoteId === note.id" style="position: relative;">
              <textarea v-model="editedNote" class="form-control" rows="3"></textarea>
              <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                <button @click="saveEdit(note)" class="btn btn-sm btn-success" style="flex: 1; margin-right: 5px;">
                  <span class="material-icons">check</span>
                </button>
                <button @click="toggleNoteEdit(note)" class="btn btn-sm btn-danger" style="flex: 1; margin-left: 5px;">
                  <span class="material-icons">close</span>
                </button>
              </div>
            </div>
            <div v-else class="note-content">{{ note.note }}</div>
          </div>
        </div>
        <div v-else class="no-notes">
          No notes added.
        </div>
        <div class="card-footer d-flex">
          <textarea v-model="newNote" class="form-control me-2" rows="1" placeholder="Add a note..."></textarea>
          <button @click="addNote" class="btn btn-dark d-flex align-items-center">
            <span class="material-icons ms-1">send</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Person of Interest</h3>
        </div>
        <div v-if="incident?.id !== undefined" class="card-body">
          <img
            :src="firstFace.url"
            v-viewer
            alt="evidence"
            style="width: 100%"
            class="img-fluid"
          />

          <br />

          <div v-if="otherFaces?.length > 0" class="row m-t-sm">
            <div class="col-4" v-for="face in otherFaces">
              <img :src="face.url" v-viewer alt="evidence" class="img-fluid" />
            </div>
          </div>
        </div>
        <div v-else class="card-body">
          <div class="wrapper">
            <div class="sub-wrapper">
              <Loader />
            </div>
          </div>
        </div>
      </div>

      <div class="card m-t-sm">
        <div class="card-header">
          <h3 class="card-title">Timeline</h3>
        </div>
        <div class="card-body" style="max-height: 500px; overflow-y: scroll">
          <ul class="timeline">
            <li v-for="log of logs" :key="log.id + '-log'">
              <div class="timeline-time">
                {{ log.created_date }} : {{ log.created_time }} |
                {{ getUserDisplayName(log.user.email) }}
              </div>
              <div class="timeline-content" style="white-space: pre-wrap">
                <p>{{ log.description }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- MODALS -->

    <!-- Request Closure Modal -->
    <div
      class="modal fade"
      id="requestToCloseModal"
      tabindex="-1"
      aria-labelledby="requestToCloseModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="requestToCloseModalLabel">
              Request to Close
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label
                >Please select a generic reason for closing this incident</label
              >
              <select class="form-control" v-model="selectedClosureOption">
                <option
                  v-for="option in closureOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="reason"
                >Please explain why you would like to close this incident</label
              >
              <textarea
                class="form-control"
                rows="5"
                v-model="closure.reason"
              ></textarea>
              <span class="text-mute"
                >Minimum 10 characters not including spaces. (<span
                  :class="{
                    'text-danger': closureReasonLength < 10,
                    'text-success': closureReasonLength > 10,
                  }"
                  >{{ closureReasonLength }}</span
                >/10)</span
              >
            </div>

            <button
              :disabled="closureReasonLength < 10"
              class="btn btn-sm btn-primary float-right m-t-sm"
              @click="submitClosure"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload Modal -->
    <div
      class="modal fade"
      id="uploadModal"
      tabindex="-1"
      aria-labelledby="uploadModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="uploadModalLabel">Upload Evidence</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              Upload evidence to this incident. All files must have a
              description before you can upload them.
            </p>

            <p>Allowed file types:</p>
            <ul>
              <li>Images (PNG/JPG/GIF)</li>
              <li>Videos (MP4/MOV/AVI)</li>
              <li>PDF</li>
              <li>Word</li>
            </ul>

            <div class="form-group">
              <label for="file"
                >Select files to upload (you can select multiple)</label
              >
              <input
                id="fileInput"
                accept="image/*,video/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                type="file"
                @change="selectFiles($event, 'upload')"
                class="form-control"
                multiple
              />
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th>File</th>
                  <th>Size</th>
                  <th>Name</th>
                  <th>Description <span class="text-danger">*</span></th>
                  <th>{{ uploadAssets.uploading ? "Progress" : "Actions" }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in uploadAssets.files" :key="file.name">
                  <td>{{ file.rawFile.name }}</td>
                  <td>{{ file.humanSize }}</td>
                  <td style="min-width: 200px">
                    <input
                      :disabled="uploadAssets.uploading"
                      type="text"
                      class="form-control"
                      v-model="file.name"
                      placeholder="Name"
                    />
                  </td>
                  <td style="min-width: 200px">
                    <input
                      :disabled="uploadAssets.uploading"
                      type="text"
                      class="form-control"
                      v-model="file.description"
                      placeholder="Description"
                    />
                  </td>
                  <td>
                    <button
                      v-show="!uploadAssets.uploading"
                      class="btn btn-sm btn-danger"
                      @click="removeFile(file, 'upload')"
                    >
                      Remove
                    </button>
                    <p v-show="uploadAssets.uploading">
                      Progress:
                      {{
                        file.upload_progress
                          ? file.upload_progress + "%"
                          : "Pending"
                      }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              class="btn btn-sm btn-primary float-right m-t-sm"
              :disabled="!readyToUpload && !uploadAssets.uploading"
              @click="upload('upload')"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Reupload Modal -->
    <div
      class="modal fade"
      id="reuploadModal"
      tabindex="-1"
      aria-labelledby="reuploadModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reuploadModalLabel">Reupload Evidence</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div><div class="modal-body">
            <p>Reupload evidence for this incident. All files must have a description before you can upload them.</p>

            <p>Allowed file types:</p>
            <ul>
              <li>Images (PNG/JPG/GIF)</li>
              <li>Videos (MP4/MOV/AVI)</li>
              <li>PDF</li>
              <li>Word</li>
            </ul>

            <div class="form-group">
              <label for="reuploadFile">Select file to reupload</label>
              <input
                id="reuploadFile"
                accept="image/*,video/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                type="file"
                @change="selectFiles($event, 'reupload')"
                class="form-control"
                multiple
              />
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>File</th>
                  <th>Size</th>
                  <th>{{ assetReupload.uploading ? "Progress" : "Actions" }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in assetReupload.files" :key="file.name">
                  <td>{{ file.rawFile.name }}</td>
                  <td>{{ file.humanSize }}</td>
                  <td>
                    <button
                      v-show="!assetReupload.uploading"
                      class="btn btn-sm btn-danger"
                      @click="removeFile(file, 'reupload')"
                    >
                      Remove
                    </button>
                    <p v-show="assetReupload.uploading">
                      Progress:
                      {{
                        file.upload_progress
                          ? file.upload_progress + "%"
                          : "Pending"
                      }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-sm btn-primary float-right m-t-sm"
              :disabled="!readyToReupload && !assetReupload.uploading"
              @click="reupload"
            >
              Reupload
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Resubmit Modal -->
    <div
      class="modal fade"
      id="resubmitModal"
      tabindex="-1"
      aria-labelledby="resubmitModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="resubmitModalLabel">
              Resubmit Incident
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <p>
                This incident has been set to Awaiting Evidence, here you can
                resubmit it for another review.<br />Any new evidence you upload
                will be added to the existing evidence.
              </p>
              <p>
                Here is the reviewers reason for setting this to Awaiting
                Evidence: <br />
                <span
                  ><strong>{{ incident.reason }}</strong></span
                >
              </p>

              <div class="col-12">
                <label for="resub_types" class="form-label"
                  >Add Extra Types:
                </label>
                <Multiselect
                  :class="{ 'multi-dark': $store.state.auth.status.dark }"
                  mode="tags"
                  placeholder="Select types"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :multiple="true"
                  :options="availableTypes"
                  label="name"
                  track-by="type_id"
                  name="resub_types"
                  class="m-b-sm"
                  v-model="resubmission.types"
                />
              </div>

              <div class="col-6">
                <label for="resub_police_force" class="form-label"
                  >Select Police Force:
                </label>
                <Multiselect
                  :class="{ 'multi-dark': $store.state.auth.status.dark }"
                  mode="tags"
                  placeholder="Select police force"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="false"
                  :multiple="false"
                  :options="policeForces"
                  name="resub_police_force"
                  class="m-b-sm"
                  v-model="resubmission.police_force"
                />
              </div>

              <div class="col-6">
                <label for="police_reference_resub" class="form-label"
                  >Modify Police Reference:
                </label>
                <input
                  type="text"
                  class="form-control m-b-sm"
                  id="police_reference_resub"
                  v-model="resubmission.police_ref"
                />
              </div>

              <div class="col-12">
                <label for="incident_date_resub" class="form-label"
                  >Modify Date:
                </label>
                <input
                  type="datetime-local"
                  class="form-control m-b-sm"
                  id="incident_date_resub"
                  v-model="resubmission.happened_at"
                />
              </div>

              <div class="col-12">
                <label for="incident_description_resub" class="form-label"
                  >Modify Description:
                </label>
                <textarea
                  class="form-control m-b-sm"
                  rows="3"
                  id="incident_description_resub"
                  v-model="resubmission.description"
                ></textarea>
                <p class="text-mute">
                  Please provide a detailed description of the incident. This
                  will help us understand what happened.
                  <span class="float-end">
                    Minimum 100 characters.
                    <span
                      :class="{
                        'text-warning': normalizedResubmissionDescription.length < 100,
                        'text-success': normalizedResubmissionDescription.length > 99,
                      }"
                    >
                      {{ normalizedResubmissionDescription.length || 0 }}
                    </span>/100
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <p><strong>Evidence: </strong></p>
              <div class="col-12 m-b-sm">
                <p>
                  Upload evidence to this incident. All files must have a
                  description before you can upload them.
                </p>

                <p>Allowed file types:</p>
                <ul>
                  <li>Images (PNG/JPG/GIF)</li>
                  <li>Videos (MP4/MOV/AVI)</li>
                  <li>PDF</li>
                  <li>Word</li>
                </ul>

                <div class="form-group">
                  <label for="file"
                    >Select files to upload (you can select multiple)</label
                  >
                  <input
                    id="fileInput"
                    accept="image/*,video/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                    type="file"
                    @change="selectFiles($event, 'resubmission')"
                    class="form-control"
                    multiple
                  />
                </div>

                <table class="table">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th>Size</th>
                      <th>Name</th>
                      <th>Description <span class="text-danger">*</span></th>
                      <th>
                        {{ resubmission.uploading ? "Progress" : "Actions" }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="file in resubmission.files" :key="file.name">
                      <td>{{ file.rawFile.name }}</td>
                      <td>{{ file.humanSize }}</td>
                      <td style="min-width: 200px">
                        <input
                          :disabled="resubmission.uploading"
                          type="text"
                          class="form-control"
                          v-model="file.name"
                          placeholder="Name"
                        />
                      </td>
                      <td style="min-width: 200px">
                        <input
                          :disabled="resubmission.uploading"
                          type="text"
                          class="form-control"
                          v-model="file.description"
                          placeholder="Description"
                        />
                      </td>
                      <td>
                        <button
                          v-show="!resubmission.uploading"
                          class="btn btn-sm btn-danger"
                          @click="removeFile(file, 'resubmission')"
                        >
                          Remove
                        </button>
                        <p v-show="resubmission.uploading">
                          Progress:
                          {{
                            file.upload_progress
                              ? file.upload_progress + "%"
                              : "Pending"
                          }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button
                  :disabled="
                    this.resubmission.files.length === 0 ||
                    this.resubmission.files.some(
                      (file) => !file.description || file.description === ''
                    )
                  "
                  class="btn btn-sm btn-primary float-right"
                  @click="submitResubmission"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/auth-header";
import Loader from "../dashboard/Customised/Loader.vue";
import { Multiselect } from "vue-multiselect";
import { v4 } from "uuid";
import { DateTime } from "luxon";
import LargeFileUpload from "../../services/largeFileUpload";

export default {
  components: {
    Loader,
    Multiselect,
  },
  data() {
    return {
      incident: {
        id: this.$route.params.id,
      },
      assets: [],
      logs: [],
      types: [],
      witnesses: [],
      incidentNotes: [],
      newNote: '',
      hoveredNote: null,
      editedNoteId: null,
      editedNote: '',
      witness: {
        name: "",
        type: "",
        notes: "",
        submitting: false,
      },
      closure: {
        modal: null,
        reason: "",
        submitting: false,
        errors: [],
      },
      closureOptions: [
        "Resolved with the person",
        "Incident details incorrect",
        "Duplicate incident",
        "No longer relevant",
        "Other",
      ],
      selectedClosureOption: null,
      editing: false,
      availableTypes: [],
      original: {
        incident: {},
        assets: [],
        logs: [],
        types: [],
      },
      saving: false,
      uploadAssets: {
        modal: null,
        files: [],
        uploading: false,
      },
      policeForces: [
        "Avon and Somerset Constabulary",
        "Bedfordshire Police",
        "Cambridgeshire Constabulary",
        "Cheshire Constabulary",
        "City of London Police",
        "Cleveland Police",
        "Cumbria Constabulary",
        "Derbyshire Constabulary",
        "Devon & Cornwall Police",
        "Dorset Police",
        "Durham Constabulary",
        "Essex Police",
        "Gloucestershire Constabulary",
        "Greater Manchester Police",
        "Hampshire Constabulary",
        "Hertfordshire Constabulary",
        "Humberside Police",
        "Kent Police",
        "Lancashire Constabulary",
        "Leicestershire Police",
        "Lincolnshire Police",
        "Merseyside Police",
        "Metropolitan Police Service",
        "Norfolk Constabulary",
        "North Yorkshire Police",
        "Northamptonshire Police",
        "Northumbria Police",
        "Nottinghamshire Police",
        "South Yorkshire Police",
        "Staffordshire Police",
        "Suffolk Constabulary",
        "Surrey Police",
        "Sussex Police",
        "Thames Valley Police",
        "Warwickshire Police",
        "West Mercia Police",
        "West Midlands Police",
        "West Yorkshire Police",
        "Wiltshire Police",
      ],
      resubmission: {
        resubmitting: false,
        modal: null,
        types: [],
        police_force: null,
        police_ref: null,
        happened_at: null,
        description: null,
        files: [],
        uploading: false,
      },
      hoveredFileId: null,
      reuploadLink: null,
      assetReupload: {
        modal: null,
        files: [],
        uploading: false,
      },
    };
  },
  mounted() {
    this.closure.modal = new bootstrap.Modal(
      document.getElementById("requestToCloseModal")
    );
    this.uploadAssets.modal = new bootstrap.Modal(
      document.getElementById("uploadModal")
    );
    this.resubmission.modal = new bootstrap.Modal(
      document.getElementById("resubmitModal")
    );
    this.getIncident();
    this.getTypes();
  },
  methods: {
    getIncident() {
      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents`, {
          params: {
            incident_id: this.incident.id,
            site_id: this.$route.query.site_id || 0,
          },
          headers: authHeader(),
        })
        .then((response) => {
          response.data.incident.happened_at = new Date(
            response.data.incident.happened_at
          )
            .toISOString()
            .slice(0, 16);
          this.incident = response.data.incident;
          this.assets = response.data.assets;
          this.assets.forEach((asset) => {
            asset.filetype = this.determineAssetType(
              asset.filename,
              asset.mime || ""
            );
          });
          response.data.logs.forEach((log) => {
            log.created_date = new Date(log.created_at).toLocaleDateString();
            log.created_time = new Date(log.created_at).toLocaleTimeString();
          });
          this.logs = response.data.logs;
          this.types = response.data.types;
          response.data.witnesses.forEach((witness) => {
            witness.created_date = new Date(
              witness.created_at
            ).toLocaleDateString();
            witness.created_time = new Date(
              witness.created_at
            ).toLocaleTimeString();
          });
          this.witnesses = response.data.witnesses;
          this.getNotes();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error loading incident data",
            "Error"
          );
        });
    },
    determineAssetType(filename, mime) {
      if (mime.includes("image")) return "image";
      if (mime.includes("video")) return "video";
      if (mime.includes("pdf")) return "pdf";
      if (mime.includes("word")) return "word";
      if (mime.includes("excel")) return "excel";
      if (mime.includes("powerpoint")) return "powerpoint";
      if (mime.includes("text")) return "text";

      switch (filename.slice(filename.lastIndexOf("."))) {
        case ".jpg":
        case ".jpeg":
        case ".png":
        case ".gif":
        case ".bmp":
        case ".webp":
        case ".svg":
        case ".ico":
        case ".tif":
        case ".tiff":
          return "image";
        case ".mp4":
        case ".webm":
        case ".ogg":
        case ".avi":
        case ".mov":
        case ".flv":
        case ".wmv":
        case ".mkv":
          return "video";
        case ".pdf":
          return "pdf";
        case ".doc":
        case ".docx":
          return "word";
        case ".xls":
        case ".xlsx":
          return "excel";
        case ".ppt":
        case ".pptx":
          return "powerpoint";
        case ".txt":
          return "text";
        default:
          return "unknown";
      }
    },
    toggleEdit() {
      if (this.editing) {
        // Check the user actually wants to cancel and lose changes
        if (
          !confirm(
            "Are you sure you want to cancel editing? All changes will be lost."
          )
        ) {
          return;
        }
      }
      this.editing = !this.editing;
      this.$refs.editButton.blur();

      if (this.editing) {
        this.original.incident = JSON.parse(JSON.stringify(this.incident));
        this.original.assets = JSON.parse(JSON.stringify(this.assets));
        this.original.logs = JSON.parse(JSON.stringify(this.logs));
        this.original.types = JSON.parse(JSON.stringify(this.types));
      } else {
        this.incident = JSON.parse(JSON.stringify(this.original.incident));
        this.assets = JSON.parse(JSON.stringify(this.original.assets));
        this.logs = JSON.parse(JSON.stringify(this.original.logs));
        this.types = JSON.parse(JSON.stringify(this.original.types));

        this.original = {
          incident: {},
          assets: [],
          logs: [],
          types: [],
        };
      }
    },
    getTypes() {
      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents/types`, {
          headers: authHeader(),
        })
        .then((response) => {
          response.data.types.forEach((type) => {
            type.type_id = type.id;
          });
          this.availableTypes = response.data.types;
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error loading incident types",
            "Error"
          );
        });
    },
    saveChanges() {
      if (this.saving) return;
      this.saving = true;

      let data = {
        incident_id: this.incident.id,
        site_id: this.incident.site_id,
      };

      // Only send what we have updated
      if (this.incident.happened_at !== this.original.incident.happened_at) {
        data.happened_at = DateTime.fromISO(this.incident.happened_at).toISO({
          includeOffset: true,
        });
      }

      if (this.incident.police_force !== this.original.incident.police_force) {
        data.police_force = this.incident.police_force;
      }

      if (this.incident.police_ref !== this.original.incident.police_ref) {
        data.police_ref = this.incident.police_ref;
      }

      if (this.incident.description !== this.original.incident.description) {
        data.description = this.incident.description;
      }

      if (this.types !== this.original.types) {
        data.types = this.types.map((type) => type.type_id);
      }

      axios
        .patch(`https://api.varsanpr.com/customer/incident/incidents`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          this.$success("Incident updated successfully", "Success");
          this.getIncident();
          this.toggleEdit();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error updating incident",
            "Error"
          );
        });
    },
    selectFiles(ev, type) {
      const filesArray =
        type === "resubmission" ? this.resubmission :
        type === "reupload" ? this.assetReupload : this.uploadAssets;

      for (let file of ev.target.files) {
        // file.type = file.type.split('/')[0];
        filesArray.files.push({
          rawFile: file,
          humanSize: this.humanFileSize(file.size),
          name: "",
          description: "",
          upload_progress: 0,
        });
      }

      if (type === "resubmission") {
        console.log(this.resubmission.files);
      } else if (type === "reupload") {
        console.log(this.assetReupload.files);
      } else {
        console.log(this.uploadAssets.files);
      }
    },
    humanFileSize(size) {
      let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    removeFile(file, type) {
      const filesArray =
        type === "resubmission" ? this.resubmission :
        type === "reupload" ? this.assetReupload : this.uploadAssets;
      filesArray.files = filesArray.files.filter((f) => f !== file);
    },
    async upload(type) {
      const filesArray =
        type === "resubmission" ? this.resubmission : this.uploadAssets;

      if (filesArray.files.length === 0 || filesArray.uploading) return;
      // Upload each file in filesArray.files
      filesArray.uploading = true;

      /**
       * Step 1: Create the asset and get the upload URL
       * Step 2: Upload the file to the URL
       * */

      // Step 1
      for await (let file of filesArray.files) {
        let data = {
          incident_id: this.incident.id,
          site_id: this.incident.site_id,
          mime: file.rawFile.type,
          description: file.description,
          name: file.name || file.rawFile.name,
          extension: file.rawFile.name.split(".").pop(),
        };
        data.extension = data.extension ? `.${data.extension}` : '';

        let response = await axios
          .post(
            `https://api.varsanpr.com/customer/incident/incidents/asset`,
            data,
            {
              headers: authHeader(),
            }
          )
          .catch((error) => {
            this.$error(
              error.response.data.message || "Error creating asset",
              "Error"
            );
          });

        // Step 2
        if (response) {
          let uploader = new LargeFileUpload(file.rawFile, response.data.sas);
          uploader.on("progress", (progress) => {
            file.upload_progress = progress;
          });
          let uploadResponse = await uploader.upload();
        }
      }

      filesArray.files = [];
      filesArray.modal.hide();
      filesArray.uploading = false;
      this.getIncident();
    },
    getReuploadLink(asset_id) {
      axios.get(`https://api.varsanpr.com/customer/incident/incidents/getReuploadLink`, {
        params: {
          incident_id: this.incident.id,
          site_id: this.$route.query.site_id || 0,
          asset_id: asset_id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        this.reuploadLink = response.data.sas;
        this.assetReupload.modal = new bootstrap.Modal(
          document.getElementById("reuploadModal")
        );
        this.assetReupload.modal.show();
      })
      .catch((error) => {
        this.$error(
          error.response.data.message || "Error getting reupload link",
          "Error"
        );
      });
    },
    async reupload() {
      let filesArray = this.assetReupload;

      if (filesArray.files.length === 0 || filesArray.uploading) return;

      filesArray.uploading = true;

      for (let file of filesArray.files) {
        let uploader = new LargeFileUpload(file.rawFile, this.reuploadLink);
        uploader.on("progress", (progress) => {
          file.upload_progress = progress;
        });
        let uploadResponse = await uploader.upload();
      }

      filesArray.files = [];
      filesArray.modal.hide();
      filesArray.uploading = false;
      this.getIncident();
    },
    addWitness() {
      if (this.witness.submitting) return;
      this.witness.submitting = true;

      let data = {
        site_id: this.incident.site_id,
        name: this.witness.name,
        type: this.witness.type,
        notes: this.witness.notes,
      };

      axios
        .post(
          `https://api.varsanpr.com/customer/incident/incidents/${this.incident.id}/witness`,
          data,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.$success("Witness added successfully", "Success");
          this.witness = {
            name: "",
            type: "",
            notes: "",
            submitting: false,
          };
          this.getIncident();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error adding witness",
            "Error"
          );
          this.witness.submitting = false;
        });
    },
    removeWitness(witness) {
      if (witness.submitting) return;
      witness.submitting = true;

      axios
        .delete(
          `https://api.varsanpr.com/customer/incident/incidents/${this.incident.id}/witness/${witness.id}`,
          {
            params: {
              site_id: this.incident.site_id,
            },
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.$success("Witness removed successfully", "Success");
          this.getIncident();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error removing witness",
            "Error"
          );
          witness.submitting = false;
        });
    },
    getNotes() {
      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents/notes`, {
          params: {
            incident_id: this.incident.id,
          },
          headers: authHeader(),
        })
        .then((response) => {
          response.data.notes.forEach((note) => {
            note.created_date = new Date(note.created_at).toLocaleDateString();
            note.created_time = new Date(note.created_at).toLocaleTimeString();
          });
          this.incidentNotes = response.data.notes;
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error loading incident notes",
            "Error"
          );
        });
    },
    addNote() {
      if (!this.newNote.trim()) return;

      const data = {
        note: this.newNote,
      };

      console.log(data);

      axios
        .post(`https://api.varsanpr.com/customer/incident/incidents/notes?incident_id=${this.incident.id}`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          this.$success("Note added successfully", "Success");
          this.newNote = "";
          this.getNotes();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error adding note",
            "Error"
          );
        });
    },
    toggleNoteEdit(note) {
      if (this.editedNoteId === null) {
        this.editedNoteId = note.id;
        this.editedNote = note.note;
      } else if (this.editedNoteId === note.id) {
        this.editedNoteId = null;
        this.editedNote = '';
      }
    },
    saveEdit(note) {
      if (!this.editedNote.trim()) return;

      const data = {
        note: this.editedNote,
      };

      axios
        .patch(`https://api.varsanpr.com/customer/incident/incidents/notes?incident_id=${this.incident.id}&note_id=${note.id}`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          this.$success("Note updated successfully", "Success");
          this.editedNoteId = null;
          this.editedNote = '';
          this.getNotes();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error updating note",
            "Error"
          );
        });
    },
    submitClosure() {
      if (this.closure.submitting) return;
      this.closure.submitting = true;

      let reason = `${this.selectedClosureOption}: ${this.closure.reason}`;

      let data = {
        site_id: this.incident.site_id,
        reason: reason,
      };

      axios
        .post(
          `https://api.varsanpr.com/customer/incident/incidents/${this.incident.id}/close`,
          data,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.$success("Closure request submitted successfully", "Success");
          this.closure.modal.hide();
          this.getIncident();
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error submitting closure request",
            "Error"
          );
          this.closure.submitting = false;
        });
    },
    addAsset() {
      this.resubmission.files.push({
        id: v4(),
        file: null,
        name: null,
        description: null,
      });
    },
    removeAsset(asset) {
      this.resubmission.files = this.resubmission.files.filter(
        (a) => a.id !== asset.id
      );
    },
    openResubmissionModal() {
      if (this.resubmission.types.length <= 0) {
        this.resubmission.types = this.types;
      }
      if (
        this.resubmission.happened_at === null &&
        this.incident?.happened_at
      ) {
        this.resubmission.happened_at = this.incident.happened_at;
      }
      if (this.resubmission.police_force === null) {
        this.resubmission.police_force = this.incident.police_force;
      }
      if (this.resubmission.police_ref === null) {
        this.resubmission.police_ref = this.incident.police_ref;
      }
      if (this.resubmission.description === null) {
        this.resubmission.description = this.normalizeDescription(this.incident.description);
      }

      this.resubmission.modal.show();
    },
    submitResubmission() {
      if (this.resubmission.resubmitting) return;
      this.resubmission.resubmitting = true;

      let data = {
        incident_id: this.incident.id,
        site_id: this.incident.site_id,
        resubmit: true,
      };

      if (this.resubmission.types !== this.incident.types) {
        data.types = this.resubmission.types.map((type) => type.type_id);
      }
      if (this.resubmission.happened_at !== this.incident.happened_at) {
        data.happened_at = DateTime.fromISO(
          this.resubmission.happened_at
        ).toISO({
          includeOffset: true,
        });
      }
      if (this.resubmission.police_force !== this.incident.police_force) {
        data.police_force = this.resubmission.police_force;
      }
      if (this.resubmission.police_ref !== this.incident.police_ref) {
        data.police_ref = this.resubmission.police_ref;
      }
      if (this.resubmission.description !== this.incident.description) {
        data.description = this.resubmission.description;
      }

      axios
        .patch(`https://api.varsanpr.com/customer/incident/incidents`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          this.$success("Incident resubmitted successfully", "Success");
          this.upload("resubmission");
        })
        .catch((error) => {
          this.$error(
            error.response.data.message || "Error updating incident",
            "Error"
          );
        });
    },
    getUserDisplayName(email) {
      return email.endsWith("@varstechnology.co.uk") ? "VARS Staff" : email;
    },
    awaitingEvidenceCheck() {
      if (this.incident?.status === "AwaitingEvidence") {
        const userConfirmed = confirm("This incident is Awaiting Evidence and uploading new evidence here will not resubmit the incident! Please use the resubmission form for another review.");
        if (userConfirmed) {
          this.openResubmissionModal();
        }
      } else {
        this.uploadAssets.modal.show();
      }
    },
    normalizeDescription(description) {
      return description ? description.replace(/\s+/g, ' ').trim() : '';
    }
  },
  computed: {
    severity() {
      let severityValue = this.incident?.severity || -1;
      if (severityValue === -1) return "unknown";
      if (severityValue < 250) {
        return "low";
      } else if (severityValue < 500) {
        return "med";
      } else if (severityValue < 750) {
        return "high";
      } else if (severityValue >= 750) {
        return "critical";
      } else {
        return "unknown";
      }
    },
    incident_date() {
      return new Date(this.incident?.happened_at).toLocaleDateString();
    },
    incident_time() {
      return new Date(this.incident?.happened_at).toLocaleTimeString();
    },
    created_date() {
      return new Date(this.incident?.created_at).toLocaleDateString();
    },
    created_time() {
      return new Date(this.incident?.created_at).toLocaleTimeString();
    },
    firstFace() {
      return this.faces[0] || { url: "https://placehold.co/250" };
    },
    otherFaces() {
      return this.faces.slice(1);
    },
    faces() {
      return this.assets.filter((asset) => asset.type === "FaceImg");
    },
    evidence() {
      return this.assets.filter((asset) => asset.type !== "FaceImg");
    },
    closureReasonLength() {
      let reason = this.closure.reason || "";
      // remove white spaces
      reason = reason.replace(/\s/g, "");
      return reason.length;
    },
    readyToUpload() {
      if (this.uploadAssets.files.length === 0) return false;
      for (let file of this.uploadAssets.files) {
        if (file.description.length < 1) return false;
      }
      return true;
    },
    readyToReupload() {
      if (this.assetReupload.files.length === 0) return false;
      return true;
    },
    normalizedResubmissionDescription() {
      let description = this.resubmission.description || "";
      return description.replace(/\s+/g, ' ').trim();
    }
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}
/* Critical should flash */
.sev-critical {
  background-color: red;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  animation: flash 1s infinite;
  margin-right: 5px;
}

@keyframes flash {
  0%,
  49% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

.sev-high {
  background-color: red;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.sev-med {
  background-color: orange;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.sev-low {
  background-color: green;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.sev-unknown {
  background-color: grey;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.timeline {
  display: flex;
  flex-direction: column-reverse;
  list-style: disc;
}

.timeline-time {
  color: rgba(255, 255, 255, 0.6);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.timeline-action {
  font-style: italic;
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
}

.sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.note-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  max-width: 60%;
}

.own {
  margin-left: auto;
}
.note-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: bold;
}

.note-content {
  white-space: pre-wrap;
  margin-top: 5px;
}

.no-notes {
  text-align: center;
  font-style: italic;
  color: #888;
  padding: 20px;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
}
</style>
